
<template v-slot:body>
  <b-overlay :show="loading">
    <b-row>
        <b-col>
            <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="datas.org_id">
                {{ $t('externalTraining.trainee_evaluation') + ' ' + $t('globalTrans.details') }}
            </list-report-head>
        </b-col>
    </b-row>
    <b-row>
      <b-col lg="12" sm="12">
        <b-table-simple class="tg mt-3" hover small caption-top>
          <tbody>
            <tr>
              <th style="width: 13%"  class="text-left">{{ $t('elearning_iabm.circular_memo_no') }}</th>
              <th style="width: 37%" class="text-left"> : {{ datas.circular_memo_no }}</th>
              <th style="width: 13%"  class="text-left">{{ $t('elearning_config.training_type') }}</th>
              <th style="width: 37%" class="text-left"> : {{ $i18n.locale === 'bn' ? datas.training_type_bn : datas.training_type }}</th>
              <!-- <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.organization_name_bn : formData.organization_name }}</td>
              <th style="width: 15%"  class="text-left">{{ $t('elearning_config.training_type') }}</th>
              <th style="width: 5%" class="text-center">:</th>
              <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.training_type_bn : formData.training_type }}</td> -->
            </tr>
            <tr>
              <th style="width: 13%"  class="text-left">{{ $t('globalTrans.organization') }}</th>
              <th style="width: 37%" class="text-left"> : {{ $i18n.locale === 'bn' ? datas.org_bn : datas.org }}</th>
              <th style="width: 13%"  class="text-left">{{ $t('elearning_config.training_title') }}</th>
              <th style="width: 37%" class="text-left"> : {{ $i18n.locale === 'bn' ? datas.training_title_bn : datas.training_title }}</th>
              <!-- <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.training_title_bn : formData.training_title }}</td> -->
              <!-- <th style="width: 15%"  class="text-left">{{ $t('elearning_tpm.course_module') }}</th>
              <th style="width: 5%" class="text-center">:</th>
              <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.course_module_bn : formData.course_module }}</td> -->
            </tr>
            <tr>
              <th style="width: 13%"  class="text-left">{{ $t('elearning_config.fiscal_year') }}</th>
              <th style="width: 37%" class="text-left"> : {{ $i18n.locale === 'bn' ? datas.fiscal_year_bn : datas.fiscal_year }}</th>
              <th style="width: 13%"  class="text-left">{{ $t('externalTraining.batch') }}</th>
              <th style="width: 37%" class="text-left"> : {{ $i18n.locale === 'bn' ? datas.batch_bn : datas.batch }}</th>
            </tr>
            <tr>
              <th style="width: 13%"  class="text-left">{{ $t('globalTrans.mark')}}</th>
              <th style="width: 37%" class="text-left"> : {{  $n(datas.total_mark, { useGrouping:false }) }}</th>
              <th style="width: 13%"  class="text-left"></th>
              <th style="width: 37%" class="text-left"></th>
            </tr>
          </tbody>
        </b-table-simple>
      </b-col>
    </b-row>
    <b-overlay :show="load">
      <b-row id="form">
        <div class="col-md-12">
          <fieldset class="p-2 w-100">
            <legend class="px-2 w-50 shadow-sm">{{ $t('externalTraining.trainee_evaluation') }} {{ $t('globalTrans.list') }}</legend>
            <table class="table" style="width:100%">
              <thead class="thead">
                <tr>
                  <th style="width:10%">{{$t('globalTrans.sl_no')}}</th>
                  <th style="width:18%">{{$t('globalTrans.name')}}</th>
                  <th style="width:18%">{{$t('globalTrans.mobile')}}</th>
                  <th style="width:18%">{{$t('elearning_config.organization')}}</th>
                  <th style="width:18%">{{$t('externalTraining.batch')}}</th>
                  <th style="width:18%">{{$t('externalTraining.obtained_mark')}}</th>
                </tr>
              </thead>
              <!-- <tr v-for="(course, index) in courses" :key="index">
                <td>
                  {{ $i18n.locale === 'bn' ? course.course_name_bn : course.course_name }}
                </td>
                <td>
                  {{ $i18n.locale === 'bn' ? course.course_details_bn : course.course_details }}
                </td>
                <td>
                  {{ $i18n.locale === 'bn' ? course.course_name_bn : course.course_name }}
                </td>
                <td>
                  {{ $i18n.locale === 'bn' ? course.course_details_bn : course.course_details }}
                </td>
                <td>
                  {{ $i18n.locale === 'bn' ? course.course_details_bn : course.course_details }}
                </td>
              </tr> -->
                <tr v-for="(data, index) in datas.manual_course_evaluations" :key="index">
                <td>
                  {{ $n(index + 1) }}
                </td>
                <td>
                  {{ getName(data.personal_info_id) }}
                </td>
                <td>
                  {{  $n(data.mobile, { useGrouping:false }) }}
                </td>
                <td>
                  {{ $i18n.locale === 'bn' ? datas.org_bn : datas.org }}
                </td>
                <td>
                  {{ $i18n.locale === 'bn' ? datas.batch_bn : datas.batch }}
                </td>
                <td>
                  {{ $n(data.obtained_mark, { useGrouping:false }) }}
                </td>
                </tr>
            </table>
          </fieldset>
        </div>
      </b-row>
    </b-overlay>
  </b-overlay>
</template>
<script>

import flatpickr from 'flatpickr'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import ExportPdf from './export_pdf_details'
import { courseWiseModule, batchWiseTrainee } from '../../api/routes'
export default {
  components: {
    ListReportHead
  },
  // props: ['listToModalData'],
  props: {
    datas: {
      type: Object,
      required: true
    }
  },
  created () {
    // alert(this.datas.org_id)
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
      this.getCourseInfo(tmp.course_module_id)
    }
    this.getUserName()
  },
  mounted () {
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0
      },
      officeTypeList: [],
      courses: [],
      userData: [],
      officeList: [],
      roomlist: [],
      floorlist: [],
      roomRentList: [],
      trainingCategoryList: [],
      dateErrorMsg: '',
      load: false,
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainingTitleList: []
    }
  },
  computed: {
    loading () {
      return this.$store.state.commonObj.loading
    },
    roomTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.roomTypeList.filter(item => item.status === 1)
    },
    orgList: function () {
      return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
    },
    fiscalYearList: function () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    trainingTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    }
  },
  methods: {
    async getCourseInfo (courseModuleId) {
      if (courseModuleId) {
        this.load = true
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, courseWiseModule + '/' + this.formData.course_module_id)
        if (!result.success) {
          this.courses = []
        } else {
          this.courses = result.data
        }
        this.load = false
      }
    },
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.datas.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getRoomRentList (typeId) {
      return this.$store.state.TrainingElearning.commonObj.roomRentList.filter(item => item.status === 1 && item.room_type_id === typeId)
    },
    getRoomList (typeId) {
      const roomlist = this.$store.state.TrainingElearning.commonObj.accomodationSetupList.filter(item => item.status === 1 && item.room_type_id === typeId)
      const tempData = roomlist.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.room_no, value: item.room_no }
        } else {
          return { text: item.room_no, value: item.room_no }
        }
      })
      return tempData
    },
    getFloorList (typeId) {
      const floorlist = this.$store.state.TrainingElearning.commonObj.accomodationSetupList.filter(item => item.status === 1 && item.room_type_id === typeId)
      const tempData = floorlist.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.floor_no, value: item.floor_no }
        } else {
          return { text: item.floor_no, value: item.floor_no }
        }
      })
      return tempData
    },
    getOfficeTypeList (orgId) {
      const OfficeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
      if (orgId) {
        return OfficeTypeList.filter(item => item.org_id === orgId)
      }
      return OfficeTypeList
    },
    getOfficeList (officeTypeId) {
      const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return officeList.filter(item => item.office_type_id === officeTypeId)
      }
      return officeList
    },
    getCategoryList (typeId) {
       const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
       if (typeId) {
         return trainingCategoryList.filter(item => item.type_id === typeId)
       }
       return trainingCategoryList
    },
    pdfExport () {
      const reportTitle = this.$t('externalTraining.trainee_evaluation') + ' ' + this.$t('globalTrans.details')
      ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', this.datas.org_id, reportTitle, this.datas, this)
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.category_id === categoryId)
      }
      return trainingTitleList
    },
    async getUserName (id) {
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, batchWiseTrainee)
      this.userData = result.data
    },
    getName (id) {
      const trainingTitleList = this.userData.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
          return trainingTitleList.text_bn
        } else {
          return trainingTitleList.text_en
        }
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
